var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _vm.threads
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              class: ["col-sm-4", { "d-none": !_vm.showList && _vm.isMobile }]
            },
            [
              _c("h5", { staticClass: "mt-3" }, [_vm._v("Threads")]),
              _vm._v(" "),
              _c("div", { staticClass: "input-group mb-3" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedBrand,
                        expression: "selectedBrand"
                      }
                    ],
                    staticClass: "form-select",
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedBrand = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.filterThreads
                      ]
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("All Brands")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.brandList, function(brand) {
                      return _c(
                        "option",
                        { key: brand.id, domProps: { value: brand.id } },
                        [_vm._v(_vm._s(brand.name))]
                      )
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "list-group threads-list" },
                _vm._l(_vm.filteredThreads, function(thread) {
                  return _c(
                    "li",
                    {
                      key: thread._id,
                      staticClass: "list-group-item list-group-item-action",
                      class: {
                        active:
                          _vm.selectedThread &&
                          _vm.selectedThread._id === thread._id
                      },
                      on: {
                        click: function($event) {
                          _vm.selectThread(thread)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.threadName(thread)) +
                          " - " +
                          _vm._s(_vm.getLastMessageTimestamp(thread)) +
                          "\n          "
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "status-indicator",
                          class: _vm.getThreadStatus(thread)
                        },
                        [
                          _c("v-icon", {
                            attrs: {
                              name:
                                _vm.getThreadStatus(thread) === "takeover"
                                  ? "user-circle"
                                  : _vm.getThreadStatus(thread) === "converted"
                                    ? "check-circle"
                                    : "circle"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                })
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: ["col-sm-8", { "d-none": _vm.showList && _vm.isMobile }] },
            [
              _vm.selectedThread
                ? _c(
                    "div",
                    [
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "chat-back-btn",
                            on: { click: _vm.clickBack }
                          },
                          [_c("v-icon", { attrs: { name: "arrow-left" } })],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "options-dropdown btn-group" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "dropdown-toggle thread-options",
                                attrs: {
                                  type: "button",
                                  "data-toggle": "dropdown",
                                  "aria-haspopup": "true",
                                  "aria-expanded": "false"
                                }
                              },
                              [_c("v-icon", { attrs: { name: "ellipsis-v" } })],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass: "dropdown-menu dropdown-menu-right"
                              },
                              [
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: { click: _vm.deleteThread }
                                    },
                                    [_vm._v("Delete")]
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.selectedThread.status === "takeover" ||
                                _vm.selectedThread.status === "active"
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: _vm.takeOverThreadToggle
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedThread.status ==
                                              "takeover"
                                                ? "Release"
                                                : "Takeover"
                                            )
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticStyle: { clear: "both" } })
                      ]),
                      _vm._v(" "),
                      _c("Chat", {
                        attrs: {
                          messages: _vm.selectedThread.messages,
                          "send-message": _vm.sendMessage
                        }
                      })
                    ],
                    1
                  )
                : _c("div", [
                    _c("p", [_vm._v("Select a thread to view messages.")])
                  ])
            ]
          )
        ])
      : _c("div", [
          _vm.loading
            ? _c("div", [_c("p", [_vm._v("Loading...")])])
            : _c("div", [_c("p", [_vm._v("No threads found.")])])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-52380718", { render: render, staticRenderFns: staticRenderFns })
  }
}